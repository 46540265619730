import File from "@/scripts/models/file.model";
import CampaignAppreciationDonationProduct from "@/scripts/models/campaignAppreciationDonationProduct.model";
import CampaignAppreciationItem from "@/scripts/models/campaignAppreciationItem.model";
export default class CampaignAppreciation {

	constructor() {
		this.id = 0;
		this.campaignId = 0;
		this.description = '';
		this.firstValue = 0;
		this.lastValue = 0;
		this.campaignProductId = 0;
		this.file = new File();
		this.htmlContent = true;
		this.name = '';
		this.order = null;
		this.campaignAppreciationItems = []
		// this.campaignAppreciationDonationProducts =[ new CampaignAppreciationDonationProduct()];
	}
}